<template>
  <diva-card>
    <template v-if="pack">
      <div class="row">
        <plan-world-map :pack="pack" v-if="showMap"></plan-world-map>
      </div>
      <div class="row">
        <div class="text-center">
          <h3>{{getTrans('messages.camsites')}}</h3>
          <div class="row justify-content-center">
            <div class="col-6">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <h3>{{getTrans('messages.countries')}}</h3>
          <div class="row justify-content-center text-left">
            <div class="col-12">
              <div
                v-if="
                  !pack.targeting.countries ||
                    pack.targeting.countries.length == 0
                "
              >
                <span>
                  {{getTrans('messages.all_countries')}}
                </span>
              </div>
              <div v-else>
                <div class="row">
                  <div
                    class="col-4"
                    v-for="country in countryCodes(pack).codes"
                    :key="country.code"
                  >
                    <country-flag
                      :type="'square'"
                      :code="country.code"
                    ></country-flag>
                    {{ country.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>       
      <div class="row">
        <div class="text-center">
          <h3>{{getTrans('messages.networks')}}</h3>
          <hr />
          <div
            v-if="
              !pack.targeting.networks ||
                pack.targeting.networks.length == 0
            "
            class="col-12 text-center"
          >
            <span>
              {{getTrans('messages.all_networks')}}
            </span>
          </div>
          <div class="row" v-else>
            <div
              class="col-4 text-left"
              v-for="network in pack.targeting.networks"
              :key="network"
            >
              <span>
                {{
                  capitalizeFirstLetter(
                    pack.targetingData.networks[network]
                  )
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <h3>{{getTrans('messages.sources')}}</h3>
          <hr />
          <div
            v-if="
              !pack.targeting.sources ||
                pack.targeting.sources.length == 0
            "
            class="col-12 text-center"
          >
            <span>
              {{getTrans('messages.all_sources')}}
            </span>
          </div>
          <div class="row" v-else>
            <div
              class="col-4 text-left"
              v-for="source in pack.targeting.sources"
              :key="source"
            >
              <span>
                {{
                  capitalizeFirstLetter(
                    pack.targetingData.sources[source]
                  )
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <h3>{{getTrans('messages.device_types')}}</h3>
          <hr />
          <div
            v-if="
              !pack.targeting.device_types ||
                pack.targeting.device_types.length == 0
            "
            class="col-12 text-center"
          >
            <span>
              {{getTrans('messages.all_device_types')}}
            </span>
          </div>
          <div class="row" v-else>
            <div
              class="col-4 text-left"
              v-for="deviceType in pack.targeting.device_types"
              :key="deviceType"
            >
              <span>
                {{
                  capitalizeFirstLetter(
                    pack.targetingData.device_types[deviceType]
                  )
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <h3>{{getTrans('messages.brands')}}</h3>
          <hr />
          <div
            v-if="
              !pack.targeting.device_brands ||
                pack.targeting.device_brands.length == 0
            "
            class="col-12 text-center"
          >
            <span>
              {{getTrans('messages.all_brands')}}
            </span>
          </div>
          <div class="row" v-else>
            <div
              class="col-4 text-left"
              v-for="brand in pack.targeting.device_brands"
              :key="brand"
            >
              <span>
                {{
                  capitalizeFirstLetter(
                    pack.targetingData.device_brands[brand]
                  )
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <h3>{{getTrans('messages.browsers')}}</h3>
          <hr />
          <div
            v-if="
              !pack.targeting.browsers ||
                pack.targeting.browsers.length == 0
            "
            class="col-12 text-center"
          >
            <span>
              {{getTrans('messages.all_browsers')}}
            </span>
          </div>
          <div class="row" v-else>
            <div
              class="col-4 text-left"
              v-for="browser in pack.targeting.browsers"
              :key="browser"
            >
              <span>
                {{
                  capitalizeFirstLetter(
                    pack.targetingData.browsers[browser]
                  )
                }}</span
              >
            </div>
          </div>
        </div>
      </div> 
      <div class="row">
        <div class="text-center">
          <h3>{{getTrans('messages.os')}}</h3>
          <hr />
          <div
            v-if="!pack.targeting.os || pack.targeting.os.length == 0"
            class="col-12 text-center"
          >
            <span>
              {{getTrans('messages.all_os')}}
            </span>
          </div>
          <div class="row" v-else>
            <div
              class="col-4 text-left"
              v-for="os in pack.targeting.os"
              :key="os"
            >
              <span>
                {{
                  capitalizeFirstLetter(pack.targetingData.os[os])
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="text-center">
          <h3>{{getTrans('messages.bids')}}</h3>
          <hr />
          <div class="row">
            <div
              class="col-4 text-center"
              v-for="(bid, index) in pack.bids"
              :key="index"
            >
              <bid-card
                :bid="bid"
                :targetingData="pack.targetingData"
              ></bid-card>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <content-loader></content-loader>
    </template>
  </diva-card>
</template>
<script>
// Components
import PlanWorldMap from "@/components/Diva/Plan/PlanWorldMap.vue";
import CountryFlag from "@/components/Diva/Tags/CountryFlag.vue";
import StringFormatter from "@/mixins/StringFormatter.vue";
import BidCard from "@/components/Diva/Plan/BidCard.vue";
import { mapGetters } from "vuex";

export default {
  props: { 
    packId: [String, Number], 
    showMap: {
      type: Boolean,
      default: true,
    }
  },
  name: "package-details",
  mixins: [StringFormatter],
  components: {
    PlanWorldMap,
    BidCard,
    CountryFlag,
  },
  data() {
    return {
      wait: 0,
      bids: [],
      currentCamsites: null,
      addType: [
        { type: 1, name: "pop" },
        { type: 2, name: "native" },
        { type: 3, name: "push" },
      ],
      deviceIcons: [
        { name: "TV", icon: "fa fa-tv" },
        { name: "Computer", icon: "fa fa-desktop" },
        { name: "Mobile", icon: "fa fa-mobile-alt" },
        { name: "Tablet", icon: "fa fa-tablet-alt" },
        { name: "Bot", icon: "fa fa-robot" },
        { name: "Camera", icon: "fa fa-camera" },
        { name: "Console", icon: "fa fa-gamepad" },
        { name: "Netbook", icon: "fa fa-laptop" },
        { name: "Glass", icon: "fa fa-glasses" },
        { name: "SetTopBox", icon: "fa fa-square" },
        { name: "Watch", icon: "fa fa-clock" },
      ],
    };
  },
  computed: {
    ...mapGetters("Country", [
      "getCountries",
      "getCountryById",
      "getCountryCodes",
      "getAllCountryCodesForMap",
    ]),
    ...mapGetters("Plan", {
      pack: "getData"
    }),
    ...mapGetters("Browser", ["getBrowsers", "getBrowserById"]),
    ...mapGetters("Os", ["getOs", "getOsById"]),
    ...mapGetters("Platform", ["getCamsites"]),
    ...mapGetters("Network", ["getNetworks", "getNetworkById"]),
    ...mapGetters("Country", ["getCountries", "getCountryById"]),
    ...mapGetters("Source", ["getSources"]),
    ...mapGetters("DeviceType", ["getDeviceTypes"]),
    ...mapGetters("DeviceType", ["getDeviceTypeById"]),
    viewsPerTokens() {
      if (this.pack && this.pack.avg_bid) {
        return Math.floor(100 / this.pack.avg_bid);
      } else {
        return "-";
      }
    },
  },
  mounted() {
    if (!this.packId) {
      this.$router.back();
    } else {
      if (!this.pack) {        
        this.wait++;
        this.$store.dispatch("Plan/getOne", this.packId).then(() => {
        });
      }
    }
  },
};
</script>
